<template>
    <div class="app-container">
        <el-form :inline="true" size="small" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">

            <el-form-item prop="industry" lable="行业">
                <el-select v-model="industryValue" filterable remote reserve-keyword placeholder="请搜索选择行业..."
                    :remote-method="selectStandardRecipeIndustry" :loading="loading" @change="industryChange()">
                    <el-option v-for="item in industryNoList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item prop="series" lable="系列">
                <el-select v-model="seriesValue" filterable remote reserve-keyword placeholder="请搜索选择系列号..."
                    :remote-method="selectStandardRecipeSeries" :loading="loading" @change="seriesChange()">
                    <el-option v-for="item in seriesNoList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item prop="surface" lable="表面">
                <el-select v-model="surfaceValue" filterable remote reserve-keyword placeholder="请搜索选择表面..."
                    :remote-method="selectStandardRecipeSurface" :loading="loading">
                    <el-option v-for="item in surfaceNoList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item prop="gloss" lable="光泽">
                <el-select v-model="glossValue" filterable remote reserve-keyword placeholder="请搜索选择光泽..."
                    :remote-method="selectStandardRecipeGloss" :loading="loading">
                    <el-option v-for="item in glossNoList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <!-- <el-form-item prop="status" lable="状态">
                <el-select v-model="status" placeholder="状态">
                    <el-option value="0,1" label="全部"></el-option>
                    <el-option value="0" label="可用"></el-option>
                    <el-option value="1" label="归档"></el-option>
                </el-select>
            </el-form-item> -->

            <el-form-item label="创建时间" prop="createDates">
                <div class="block">
                    <el-date-picker v-model="dataForm.createDates" value-format="yyyy-MM-dd" type="daterange"
                        range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </div>
            </el-form-item>

            <el-form-item>
                <el-button @click="restPage()" size="small">查询</el-button>
                <el-button @click="reset()" size="small">重置</el-button>
                <el-button v-if="isAuth('recipe:recipe:import')" type="success" @click="uploadHandle()"
                    :disabled="dataList.length < 0">导入</el-button>
                <el-button v-if="isAuth('recipe:recipe:import')" type="primary" @click="excelTemplateHandle()"
                    size="small">Excel模板</el-button>
            </el-form-item>


        </el-form>
        <el-table :data="dataList" border size="small" v-loading="dataListLoading" @selection-change="selectionChangeHandle"
            style="width: 100%">
            <el-table-column type="selection" fixed="left" header-align="center" align="center" width="50">
            </el-table-column>
            <el-table-column label="名称">
                <template header-align="center" align="center" slot-scope="scope">
                    <!-- 在姓名数据上添加点击事件 -->
                    <span class="clickable-text" @click="showStandardRecipe(scope.row)">{{ scope.row.recipeName }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="industry" header-align="center" align="center" label="行业">
            </el-table-column>
            <el-table-column prop="series" header-align="center" align="center" label="系列">
            </el-table-column>

            <el-table-column prop="gloss" header-align="center" align="center" label="光泽">
            </el-table-column>
            <el-table-column prop="versionNumber" header-align="center" align="center" label="版本">
            </el-table-column>
            <el-table-column prop="createTime" header-align="center" align="center" label="创建时间">
            </el-table-column>
            <!-- <el-table-column prop="status" header-align="center" align="center" label="状态"> -->
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <el-tag :type="getStatusTagType(scope.row.status)">
                        {{ getStatusText(scope.row.status) }}
                    </el-tag>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination size="small" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
            :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!-- 上传标准配方数据-->
        <upload v-if="uploadVisible" ref="upload" @refreshDataList="getDataList"></upload>
        <doubleRecipe v-if="RecipeVisible" ref="oneRecipe" @refreshDataList="getDataList"></doubleRecipe>
    </div>
</template>
<script>
import {
    selectStandardRecipeIndustry,
    selectStandardRecipeSeries,
    selectStandardRecipeSurface,
    selectStandardRecipeGloss,
    excelTemplateHandle,
    queryStandardRecipeList
} from "@/api/modules/recipe";
import Upload from "./oss-upload";
import doubleRecipe from "./doubleRecipe";
import fileSaver from 'file-saver'
export default {
    data() {
        return {
            industryNoList: [],
            industryList: [],
            seriesNoList: [],
            seriesList: [],
            surfaceNoList: [],
            surfaceList: [],
            glossNoList: [],
            glossList: [],
            value: [],
            industryValue: '',
            seriesValue: '',
            surfaceValue: '',
            glossValue: '',
            status: '',
            loading: false,
            dataForm: {
                createDate: "",
                daterange: [],
            },
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListLoading: false,
            dataListSelections: [],
            uploadVisible: false,
            addOrUpdateVisible: false,
            RecipeVisible: false,
            isOne: 0
        };
    },
    created() {
        this.getDataList()
    },
    components: {
        Upload,
        doubleRecipe
    },
    methods: {
        // 输入行业
        selectStandardRecipeIndustry(query) {
            
            if (query !== '') {
                this.loading = true;
                if (query.length > 1) {
                    let params = {
                        industry: query
                    }
                    selectStandardRecipeIndustry(params).then((res) => {
                        if (res.data.code === 0) {
                            this.industryList = res.data.list
                            const arr = this.industryList.map(item => {
                                const newitem = { label: '', value: 0 }
                                newitem.label = item
                                newitem.value = item

                                return newitem
                            })
                            this.industryNoList = arr
                        }
                    })
                }
                setTimeout(() => {
                    this.loading = false;
                    this.industryNoList = this.industryList.filter(item => {
                        return item.label.toLowerCase()
                            .indexOf(query.toLowerCase()) > -1;
                    });
                }, 200);
            } else {
                this.industryNoList = [];
            }
        },
        // 输入系列号
        selectStandardRecipeSeries(query) {
            if (query !== '') {
                this.loading = true;
                if (query.length > 2) {
                    let params = {
                        series: query
                    }
                    selectStandardRecipeSeries(params).then((res) => {
                        if (res.data.code === 0) {
                            this.seriesList = res.data.list
                            const arr = this.seriesList.map(item => {
                                const newitem = { label: '', value: 0 }
                                newitem.label = item
                                newitem.value = item

                                return newitem
                            })
                            this.seriesNoList = arr
                        }
                    })
                }
                setTimeout(() => {
                    this.loading = false;
                    this.seriesNoList = this.seriesList.filter(item => {
                        return item.label.toLowerCase()
                            .indexOf(query.toLowerCase()) > -1;
                    });
                }, 200);
            } else {
                this.seriesNoList = [];
            }
        },

        // 输入表面
        selectStandardRecipeSurface(query) {
            if (query !== '') {
                this.loading = true;
                if (query.length > 1) {
                    let params = {
                        surface: query
                    }
                    selectStandardRecipeSurface(params).then((res) => {
                        if (res.data.code === 0) {
                            this.surfaceList = res.data.list
                            const arr = this.surfaceList.map(item => {
                                const newitem = { label: '', value: 0 }
                                newitem.label = item
                                newitem.value = item

                                return newitem
                            })
                            this.surfaceNoList = arr
                        }
                    })
                }
                setTimeout(() => {
                    this.loading = false;
                    this.surfaceNoList = this.surfaceList.filter(item => {
                        return item.label.toLowerCase()
                            .indexOf(query.toLowerCase()) > -1;
                    });
                }, 200);
            } else {
                this.surfaceNoList = [];
            }
        },

        getStatusTagType(status) {
            // 根据状态值返回el-tag的类型，例如使用不同颜色表示不同状态
            return status === 0 ? 'success' : 'info';
        },
        getStatusText(status) {
            // 根据状态值返回显示的文本
            return status === 0 ? '归档' : '在用';
        },
        reset() {
            this.industryValue = '',
                this.seriesValue = '',
                this.surfaceValue = '',
                this.glossValue = '',
                this.status = '',
                this.dataForm.createDates = '',
                this.seriesNoList = [],
                this.seriesList = [],
                this.surfaceNoList = [],
                this.surfaceList = [],
                this.glossNoList = [],
                this.glossList = []
        },
        // 输入系光泽
        selectStandardRecipeGloss(query) {
            if (query !== '') {
                this.loading = true;
                if (query.length > 1) {
                    let params = {
                        gloss: query
                    }
                    selectStandardRecipeGloss(params).then((res) => {
                        if (res.data.code === 0) {
                            this.glossList = res.data.list
                            const arr = this.glossList.map(item => {
                                const newitem = { label: '', value: 0 }
                                newitem.label = item
                                newitem.value = item

                                return newitem
                            })
                            this.glossNoList = arr
                        }
                    })
                }
                setTimeout(() => {
                    this.loading = false;
                    this.glossNoList = this.glossList.filter(item => {
                        return item.label.toLowerCase()
                            .indexOf(query.toLowerCase()) > -1;
                    });
                }, 200);
            } else {
                this.glossNoList = [];
            }
        },

        // 多选
        selectionChangeHandle(val) {
            this.dataListSelections = val;
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageIndex = 1;
            this.getDataList();
        },

        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val;
            this.getDataList();
        },
        //excel模版
        excelTemplateHandle() {
            excelTemplateHandle().then((res) => {
                console.log(res.data)
                var fileName = '标准配方数据模版.xlsx'
                fileSaver.saveAs(res.data, fileName)
                console.log("导出文件结束")
            })
        },

        // 上传文件
        uploadHandle() {
            this.uploadVisible = true;
            this.$nextTick(() => {
                this.$refs.upload.init();
            });
        },

        //重新查询
        restPage() {
            this.pageIndex = 1;
            this.getDataList();

        },
        getDataList() {

            var startDate = null;
            var endDate = null;
            if (this.dataForm.createDates && this.dataForm.createDates.length > 0) {
                startDate = this.dataForm.createDates[0];
                endDate = this.dataForm.createDates[1];
            }
            const params = {
                page: this.pageIndex,
                limit: this.pageSize,
                industry: this.industryValue,
                series: this.seriesValue,
                surface: this.surfaceValue,
                gloss: this.glossValue,
                status: this.status,
                startDate: startDate ? startDate + " 00:00:00" : "",
                endDate: endDate ? endDate + " 23:59:59" : "",
            }
            queryStandardRecipeList(params).then((res) => {
                if (res.data.code === 0) {
                    this.dataList = res.data.page.list
                    this.totalPage = res.data.page.totalCount;
                    if (this.pageIndex > 1 && res.data.page.list.length == 0) {
                        this.pageIndex = this.pageIndex - 1;
                        this.getDataList();
                    }
                } else {
                    this.dataList = [];
                    this.totalPage = 0;
                }
                this.dataListLoading = false;
            })
        },
        showStandardRecipe(row) {
            this.RecipeVisible = true;
            this.$nextTick(() => {
                this.$refs.oneRecipe.init(row.id)
            })

        }



    }
}
</script>

<style scoped>
.clickable-text {
    color: blue;
    /* 设置字体颜色为蓝色 */
    cursor: pointer;
    /* 设置光标为小手状 */
}

/* 设置悬浮效果 */
.clickable-text:hover {
    text-decoration: underline;
    /* 鼠标悬浮时添加下划线 */
}
</style>