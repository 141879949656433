<template>
    <el-dialog
      title="上传文件"
      :close-on-click-modal="false"
      @close="closeHandle"
      :visible.sync="visible"
    >
      <el-upload
        drag
        action="13"
        :http-request="uploadExcel"
        :before-upload="beforeUploadHandle"
        multiple
        :file-list="fileList"
        :show-file-list="false"
        style="text-align: center"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          只支持Excel文件（.xls，.xlsx）
        </div>
      </el-upload>
      <p v-for="(item, index) in errorList" :key="index" style="color: #f56c6c">
        {{ item }}
      </p>
    </el-dialog>
  </template>
  
  <script>
  import { importStandardRecipeExcelData as excelImport } from "@/api/modules/recipe";
  export default {
    data() {
      return {
        visible: false,
        successNum: 0,
        errorList: [],
        fileList: [],
      };
    },
    methods: {
      init(id) {
        this.visible = true;
      },
      // 上传之前
      beforeUploadHandle(file) {
        const isExcel =
          file.name.toString().indexOf(".xls") > 0 ||
          file.name.toString().indexOf(".xlsx") > 0;
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isExcel) {
          this.$message.error("请上传文件格式不正确!");
        }
        if (!isLt10M) {
          this.$message.error("上传文件大小不能超过 10MB!");
        }
        const flag = isExcel && isLt10M;
        if (flag) {
          this.errorList = [];
        }
        return flag;
      },
      // 弹窗关闭时
      closeHandle() {
        this.fileList = [];
        this.errorList = [];
        this.$emit("refreshDataList");
      },
      uploadExcel(item) {
        let importData = new FormData();
        importData.append("file", item.file);
        excelImport(importData).then(({ data }) => {
          // if (data && data.materialNo) {
          //   const blob = new Blob([data.materialNo], { type: "text/plain" });
          //   const fileName = '不再库的物料汇总.txt';//下载文件名称
          //   const elink = document.createElement('a');
          //   elink.download = fileName;
          //   elink.style.display = 'none';
          //   elink.href = URL.createObjectURL(blob);
          //   document.body.appendChild(elink);
          //   elink.click();
          //   URL.revokeObjectURL(elink.href); // 释放URL 对象
          //   document.body.removeChild(elink);
          //   this.visible = false;
          // }
          if (data.code === 0) {
            this.$message({
              message: "数据导入成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
              },
            });
          } else {
            // return this.$alert(data.msg, "", {
            //   confirmButtonText: "确定",

            //   callback: action => {
            //   }

            // });
            this.$message({
              message: data.msg,
              type: "error",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
              },
            });
          }
        });
      },
    },
  };
  </script>
  