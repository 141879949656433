<template>
    <el-dialog
      :title="isDouble ? '双组份标准配方详情' : '单组份标准配方详情'"
      :close-on-click-modal="false"
      :visible.sync="visible"
      width="800px"
      @close="close"
    >
    <div>
      <div class="table">
        <div>
          <div class="weight">系列</div>
          <div :class="isDouble ? 'double weight' : 'one weight'">{{standardRecipeInfoVo.series}}</div>
        </div>
        <div>
          <div class="weight">名称</div>
          <div :class="isDouble ? 'double weight' : 'one weight'">{{standardRecipeInfoVo.name}}</div>
        </div>
        <div>
          <div class="weight">光泽范围</div>
          <div :class="isDouble ? 'double weight' : 'one weight'">{{standardRecipeInfoVo.glossRange}}</div>
        </div>
        <div>
          <div class="weight flex_c_C">原料</div>
          <div :class="isDouble ? 'double bottomnone' : 'one bottomnone'" style="padding: 0px">
            <div>
              <div class="weight">物料号</div>
              <div v-for="(item,index) in standardRecipeInfoVo.materials" :key="index">{{ Object.keys(item)[0]}}</div>
            </div>
            <div v-if="isDouble">
              <div class="weight">A占比</div>
              <div v-for="(item,index) in standardRecipeInfoVo.materials" :key="index">{{item[Object.keys(item)[0]][0] ? item[Object.keys(item)[0]][0] : '-'}}</div>
            </div>
            <div v-if="isDouble">
              <div class="weight">B占比</div>
              <div v-for="(item,index) in standardRecipeInfoVo.materials" :key="index">{{item[Object.keys(item)[0]][1] ? item[Object.keys(item)[0]][1] : '-'}}</div>
            </div>
            <div v-if="!isDouble">
              <div class="weight">占比</div>
              <div v-for="(item,index) in standardRecipeInfoVo.materials" :key="index">{{item[Object.keys(item)[0]][0] ? item[Object.keys(item)[0]][0] : '-'}}</div>
            </div>
          </div>
        </div>
        <div>
          <div class="weight flex_c_C">检验计划</div>
          <div :class="isDouble ? 'double bottomnone_' : 'one bottomnone_'" style="padding: 0px">
            <div>
              <div v-for="(item,index) in standardRecipeInfoVo.inspectionPlanMap" :key="index">{{ Object.keys(item)[0]}}</div>
            </div>
            <div :style="{width: isDouble ? '300px' : '150px',flex: isDouble ? '2' : '1'}">
              <div v-for="(item,index) in standardRecipeInfoVo.inspectionPlanMap" :key="index">{{item[Object.keys(item)[0]] ? item[Object.keys(item)[0]] : '-'}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="remark">
        <h4>说明</h4>
        <div class="remark_c">{{standardRecipeInfoVo.remark}}</div>
      </div>
    </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">关闭</el-button>
      </span>
    </el-dialog>
  </template>
  
  <script>
    import {
    queryStandardRecipeInfo
} from "@/api/modules/recipe";
  export default {
    data() {
      return {
        visible: false,
        standardRecipeInfoVo: {},
        isDouble: false
      };
    },
    methods: {
      init(id) {
        this.visible = true;
        let params = {
            id:id
        }
        queryStandardRecipeInfo(params).then((res)=>{
            if(res.data.code === 0){
              this.standardRecipeInfoVo = res.data.standardRecipeInfoVo
              if (res.data.size && res.data.size === 2) {
                this.isDouble = true;
              }
            }
        })
      },
      close() {
        this.standardRecipeInfoVo = {}
        this.visible = false;
        this.isDouble = false;
      },
    },
  };
  </script>
  <style rel="stylesheet/scss" lang="scss" scoped>
  .flex_c_C {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .table {
    >div{
      display: flex;
      border-left: 1px solid #999;
      >div {
        text-align: center;
        box-sizing: border-box;
        border-top: 1px solid #999;
        border-right: 1px solid #999;
        padding: 10px 5px;
      }
      .double {
        width: 450px;
        display: flex;
        justify-content: center;
        >div:last-child >div{
          border-right: 0px solid pink;
        }
        >div{
          flex: 1;
          >div {
            padding: 10px 5px;
            border-right: 1px solid #999;
            border-bottom: 1px solid #999;
          }
        }
      }
      .one {
        width: 300px;
        display: flex;
        justify-content: center;
        >div:last-child >div{
          border-right: 0px solid pink;
        }
        >div{
          flex: 1;
          >div {
            padding: 10px 5px;
            border-right: 1px solid #999;
            border-bottom: 1px solid #999;
          }
        }
      }
      .bottomnone:last-child {
        >div >div:last-child{
          border-bottom: 0px solid #999;
        }
      }
      .bottomnone_:last-child {
        >div >div:last-child{
          border-bottom: 0px solid #999;
        }
      }
      >div:nth-child(1) {
        width: 100px;
      }
    }
    >div:last-child >div{
        border-bottom: 1px solid #999;
    }
  }
  .remark{
    .remark_c {
      width:100%;
      min-height: 50px;
      padding: 10px;
      border: 1px solid #c1c1c1;
    }
  }
  .weight {
    font-weight: bold;
  }
  </style>
  